<template>
    <b-table 
      hover
      bordered
      head-variant="dark"
      no-border-collapse
      show-empty
      sticky-header
      ref="selectableTable"
      :class="'text-center'"
      :items="items"
      :fields="fields"
      :empty-text="$t('조회된 결과가 없습니다.')"
      :select-mode="'single'"
      :details-td-class="'rowDetailStyle'"
      selectable
      @row-selected="onRowSelected"
      @row-clicked="onRowDetail"
     > 
      <template #empty="scope">
        <h4>{{ scope.emptyText }}</h4>
      </template>

      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span>&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span >&nbsp;</span>
          <span class="sr-only">Not selected</span>
        </template>
      </template>

      <template #cell(index)="data">
        {{ data.index + 1 }}
      </template>

      <template #row-details="row" :class="'bg-white'">
        <b-card >
        <!-- <div class="text-left font-weight-bold p-5 d-flex" style="font-size: 12px;">
          <i class="material-icons mr-1">info</i><span class="h4 mb-0">관제점 정보</span>
        </div> -->
          <detail-box :ref="'detail'" :sidoCode="row.item.sidoCode"/>
        </b-card>
      </template>
    </b-table>
</template>
 
<style scoped>
.b-table-sticky-header {
    max-height: 760px;
}
</style>

<script>
// import detailCard from "./GridCard.vue";
import detailBox from "../../../../../sys/codes/sido/SidoCodeDetail.vue";

export default {
  components: {
    detailBox,
    },
  props: ["data"],
  data() {
    return {
        fields : null,
        items : null,
        emptyText : null,
        selectedRow: []
    };
  },
  watch: {
    data(){
      this.selectedRow._showDetails =  !this.selectedRow._showDetails;
      this.setItems();
    },
  },
  created() {
    this.setFields();
    this.setItems();
  },
  mounted(){
    
  },
  methods: {
    onRowSelected(item) {
      if(!item.length > 0){
        this.selectedRow._showDetails = false;
        this.selectedRow = [];
      }
    },
    onRowDetail(row){
      // 이전 선택된 row close
      this.selectedRow._showDetails =  !this.selectedRow._showDetails;

      // // 선택된 row open
      row._showDetails = !row._showDetails;

      // 선택된 row 저장
      this.selectedRow = row;
    },
    clearSeclected(){
      this.$refs.selectableTable.clearSelected()
    },
    setFields(){
      this.fields = [
        { key: 'index', label: global.xe.$t("순번") },
        { key: 'sidoCode', label: global.xe.$t("지역 코드")},
        { key: 'sidoCodeName', label: global.xe.$t("지역 코드명")},
        { key: 'enabled',  label: global.xe.$t("사용여부") },
      ] 
    },
    setItems(){
      this.items = this.data;
    },
    onReset(){
      this.$refs.detail.onReset();
    },
  },
};
</script>
