<template>
  <div>

    <div class="row-vh d-flex flex-row">
      <div class="w-50">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left d-flex">
            <span class="h5 mb-0">{{$t('지역 코드')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i>
          </div>
          <div class="col-8">
            <!-- <input :ref="'sidoCode'" class="form-control" v-model="model.sidoCode" disabled="disabled"/> -->
            <valid-input :ref="'sidoCode'" :inputType="'text'" :inputValue.sync="model.sidoCode" :readonly=true
              :rules="rules.REGION_CODE_RULE" :errorMessage="validMessage.REGION_CODE_VALID_MESSAGE"></valid-input>
          </div>
        </div>
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left">
            <span class="h5">{{$t('사용여부')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i>
          </div>
          <div class="col-8">
            <select class="form-control" :ref="'enabled'" v-model="model.enabled">
              <option value="">{{$t('선택')}}</option>
              <option value="Y">{{$t('사용')}}</option>
              <option value="N">{{$t('사용안함')}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="w-50">
        <div class="d-flex flex-row align-items-center mt-3">
          <div class="col-3 text-left d-flex">
            <span class="h5 mb-0">{{$t('지역 코드명')}}</span><i class="material-icons ml-1 m-t-1"
              :style="{ fontSize: '15px' }">check_circle</i>
          </div>
          <div class="col-8">
            <!-- <input :ref="'sidoCodeName'" class="form-control" v-model="model.sidoCodeName"/> -->
            <valid-input :ref="'sidoCodeName'" :inputType="'text'" :inputValue.sync="model.sidoCodeName" :readonly=true
              :rules="rules.REGION_CODE_NAME_RULE"
              :errorMessage="validMessage.REGION_CODE_NAME_VALID_MESSAGE"></valid-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.form-control-none {
  display: block;
  width: 100%;
  height: calc(1.5em + 14px + 2px);
  padding: 7px 0.75rem;
  font-size: .75rem;
  font-weight: 400;
  /* line-height: 1.5; */
  color: #2d353c;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid #d5dbe0;
  border-radius: 4px;
  -webkit-transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}
</style>

<script>
import backEndApi from "../../../../api/backEndApi";
import * as rules from "@src/consts/ruleConsts.js";
import * as validMessage from "@src/consts/validMessageConsts";

export default {
  components: {
  },
  props: ["sidoCode"],
  watch: {

  },
  data() {
    return {
      model: {
        sidoCode: "",
        sidoCodeName: "",
        enabled: "",
      },

      // define the default value
      applyZoneIdx: null,
      // define options
      options: null,
      rules,
      validMessage
    };
  },
  computed: {

  },
  created() {
    this.setModel();
  },
  methods: {
    setModel() {
      backEndApi.codes.getSidoCode(this.sidoCode).then(({ data }) => {
        this.model = {
            sidoCode: data.sidoCode,
            sidoCodeName: data.sidoCodeName,
            enabled: data.enabled
          };
      });
    },
  }
};
</script>
